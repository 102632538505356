import React, { useState, useEffect, useCallback } from 'react';
import CarouselWithMap from "./Components_2/CarouselWithMap";
import SidebarMenu from "./Components_2/sideBarMenu";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import common from "./pics/logo_top.png";
import './Pracovni_listy.css';


function Pracovni_listy() {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({ page: 1, pageCount: 0, pageSize: 10, total: 0 });

    const [pracovniListy, setPracovniListy] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCoverDivVisible, setIsCoverDivVisible] = useState(false);

    
    const fetchData = useCallback((pageNumber, pageSize, language) => {
        //console.log(process.env.REACT_APP_API_URL+`/api/kpbi-worksheets?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&locale=${language}&sort[0]=id:desc&populate=*`);
        fetch(process.env.REACT_APP_API_URL+`/api/kpbi-worksheets?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&locale=${language}&sort[0]=id:desc&populate=*`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPracovniListy(data.data);
                setLoading(false);
                setPagination(data.meta.pagination);

            })
            .catch(error => {
                setError(error);
                console.log(error);
                setLoading(false);
            });
    }, []);

    
    useEffect(() => {
        fetchData(pagination.page, pagination.pageSize, i18n.language);
        window.scrollTo(0, 0);
    }, [pagination.page, pagination.pageSize, i18n.language, fetchData]);

    // Handle page change
    const handlePageChange = (newPage) => {
        setPagination(prevState => ({ ...prevState, page: newPage }));
    };


    const viewCountUpdate =(pracovniList) => {
        if (pracovniList.attributes.pocet_zobrazeni !== undefined && pracovniList) 
        {
            const url = `${process.env.REACT_APP_API_URL}/api/kpbi-worksheets/${pracovniList.id}`;
            const viewUpdate = { pocet_zobrazeni: pracovniList.attributes.pocet_zobrazeni + 1 };

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ data: viewUpdate })
            };

            fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error updating view count');
                }
                console.log('View count updated successfully');
                //Možná vypnout
                fetchData(pagination.page, pagination.pageSize, i18n.language);
            })
            .catch(error => console.error('Error:', error));
        }
    }; 

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba...</div>;


    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                            <h3>{t("pracovni_listy")}</h3>
                            <hr className="green-line"/>
                        <div className="col-lg-12 plisty-container">
                            {pracovniListy.map((pracovniList, index) => (
                                <div key={index} className="custom-card" id={`card-${pracovniList.id}`}>
                                    <Link
                                        to={process.env.REACT_APP_API_URL + pracovniList.attributes.files.data.attributes.url}
                                        onClick={() => viewCountUpdate(pracovniList)}
                                        className="no-underline">
                                        <div className="card h-100 position-relative">
                                            <div className="box_shadow">
                                                {isCoverDivVisible === pracovniList.id ? (
                                                    <div className='cover-div'
                                                         onMouseEnter={() => setIsCoverDivVisible(pracovniList.id)}
                                                         onMouseLeave={() => setIsCoverDivVisible(null)}>
                                                        <div className='cover-text'> Máme radost, list se vám líbí. Už
                                                            si ho
                                                            otevřelo {pracovniList.attributes.pocet_zobrazeni} lidí, <Link
                                                                to={process.env.REACT_APP_API_URL + pracovniList.attributes.files.data.attributes.url}
                                                                onClick={() => viewCountUpdate(pracovniList)}
                                                                style={{color: 'black'}}>buďte další.</Link>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <img className="card-img-top"
                                                     src={pracovniList.attributes.coverImage.data ? process.env.REACT_APP_API_URL + pracovniList.attributes.coverImage.data.attributes.url : common}
                                                     alt={pracovniList.attributes.name}/>
                                                <div className="overlay">
                                                    <div className="circle-number"
                                                         onMouseEnter={() => setIsCoverDivVisible(pracovniList.id)}
                                                         onMouseLeave={() => setIsCoverDivVisible(null)}>
                                                        {pracovniList.attributes.pocet_zobrazeni + 'x'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <p className="card-title projekt_odkaz text-center">{pracovniList.attributes.name}</p>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className='d-flex justify-content-center mt-5'>
                            {pagination.pageCount > 1 && (
                                <div>
                                    <ul className='pagination'>
                                        {[...Array(pagination.pageCount)].map((_, index) => (
                                            <li
                                                key={index}
                                                className={`page-item  ${pagination.page === index + 1 ? 'active' : ''}`}
                                            >
                                                <button
                                                    className='page-link'
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                            <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate("/")}>
                                {t("zpet")}
                            </button>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default Pracovni_listy;