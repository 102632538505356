import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SidebarMenu from "./Components_2/sideBarMenu";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import CarouselWithMap from "./Components_2/CarouselWithMap";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import moment from 'moment';

function VimKamKlikamClankyDetail() {
    const [t] = useTranslation("global");
    let { id, categoryId } = useParams();

    const [vkkcDetail, setVimKamKlikamClankyDetail] = useState({ attributes: {}, categorie: { attributes: {} } });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const vkkdResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/kpbi-vim-kam-klikam-clankies/${id}?populate=*`);
                if (!vkkdResponse.ok)
                    throw new Error('Network response was not ok for vkkd');

                const vkkd = await vkkdResponse.json();

                const kategorieResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/kpbi-vim-kam-klikam-kategories/${categoryId}`);
                if (!kategorieResponse.ok)
                    throw new Error('Network response was not ok for category');

                const kategorieData = await kategorieResponse.json();

                setVimKamKlikamClankyDetail({ ...vkkd.data, categorie: kategorieData.data });
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, categoryId]);

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;
    // console.log(vkkcDetail);
    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap />
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className='details-container'>
                        <h1 className="details-title">{vkkcDetail.attributes.titulek}</h1>
                        {/* <div className='details-date'>{moment(vkkcDetail.attributes.createdDate).format('DD. MM. YYYY')}</div> */}
                        <div className='details-date'>{moment(vkkcDetail.attributes.createdDate).format('DD. MM. YYYY')}</div>
                        <h2 className="details-category">{vkkcDetail.categorie.attributes.nazev}</h2>
                        <div className="details-content"/>
                        <BlocksRenderer
                                content={
                                Array.isArray(vkkcDetail?.attributes?.text) ? vkkcDetail.attributes.text : []
                                }
                            ></BlocksRenderer>
                        {vkkcDetail.attributes.Images?.data?.length > 0 && (
                            <div className="carousel-container">
                                <Carousel>
                                    {vkkcDetail.attributes.Images.data.map((img) => (
                                        <img key={img.id} src={`${process.env.REACT_APP_API_URL}${img.attributes.url}`} alt="carousel_img" />
                                    ))}
                                </Carousel>
                            </div>
                        )}

                        <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                            {t("zpet")}
                        </button>
                    </div>
                </div>
                <SidebarMenu />
            </div>
        </div>
    );
}

export default VimKamKlikamClankyDetail;
