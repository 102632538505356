import './Menu.css';
import KpbiLogo from './pics/logo_top.png';
import {Link , useLocation} from 'react-router-dom';
import {useTranslation } from "react-i18next";

function Menu() {

    const [t, i18n] = useTranslation("global");
    const location = useLocation();
     const handleChangeLanguage = (lang) => {
         i18n.changeLanguage(lang);
    }

    const shouldHideLanguageButton = location.pathname.startsWith('/vim-kam-klikam');
    return (
        <div className="menu">
                <nav className="navbar navbar-expand-lg navbar-light text-center">
                    <div className="mx-auto">
                        <Link to="/">
                            <img src={KpbiLogo} alt="KPBIlogo"  className="img-fluid" />
                        </Link>
                    </div>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="dropdown-item" to="/oprojektu">{t("o_projektu")}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="dropdown-item" to="/aktuality-z-kraju">{t("aktuality_z_kraju")}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="dropdown-item" to="/reference">{t("reference")}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="dropdown-item" to="/hledatepomoc">{t("hledate_pomoc")}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="dropdown-item" to="/kontakt">{t("kontakt")}</Link>
                            </li>
                            {i18n.language === 'cs' && (
                                <>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="https://www.youtube.com/channel/UCRaYk6bGcGYoLw_RBNACrDA">Video</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="https://elearning.ecrime.cz/">E-Learning</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="https://www.facebook.com/kpbicz">Sociální sítě</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="/vim-kam-klikam">Články</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="/skoleni">Školení</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="/https://www.youtube.com/playlist?list=PLrT6AA3DsRDDCtiMnd9gAHsK9ZxZgWffg">Tutoriály</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="/pracovni-listy">Pracovní listy</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="https://elearning.ecrime.cz/course/view.php?id=56">Soutěžní kvíz</Link>
                                    </li>
                                </>
                            )}
                            {i18n.language === 'en' && (
                                <>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="http://elearning.ecrime.cz/course/view.php?id=2">For Kids and
                                            Students</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="http://elearning.ecrime.cz/course/view.php?id=4">For Social
                                            Workers</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="http://elearning.ecrime.cz/course/view.php?id=5">For Police
                                            Officers</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="http://elearning.ecrime.cz/course/view.php?id=6">For Teachers</Link>
                                    </li>
                                    <li className="nav-item mobile-only">
                                        <Link className="dropdown-item"
                                              to="http://elearning.ecrime.cz/course/view.php?id=17">For Senior Citizens</Link>
                                    </li>
                                </>
                            )}
                            <li className="nav-item"
                                style={{
                                    display: shouldHideLanguageButton ? 'none' : 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                 }}
                            >
                                {i18n.language === 'en' ? (
                                    <button className="btn btn_jazyk" onClick={() => handleChangeLanguage('cs')}>
                                        CZ
                                    </button>
                                ) : (
                                    <button className="btn btn_jazyk" onClick={() => handleChangeLanguage('en')}>
                                        EN
                                    </button>
                                )}
                            </li>
                        </ul>
                    </div>
                </nav>
        </div>
    );
}

export default Menu;