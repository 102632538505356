import './Obsah.css';
import React, { useState, useEffect } from 'react';
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
// import ReactMarkdown from "react-markdown";
import SidebarMenu from './Components_2/sideBarMenu';
import CarouselWithMap from "./Components_2/CarouselWithMap";
import {useTranslation} from "react-i18next";
import { useNavigate   } from 'react-router-dom';

function Info() {
    const [ t, i18n ] = useTranslation("global");
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(process.env.REACT_APP_API_URL+`/api/kpbi-o-projektu?locale=${i18n.language}`);
        fetch(process.env.REACT_APP_API_URL+`/api/kpbi-o-projektu?locale=${i18n.language}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setInfo(data.data);
                setLoading(false);
                console.log(data.data);
                
            })
            .catch(error => {
                setError(error);
                console.log(error);
                setLoading(false);
            });
    }, [i18n.language]);


    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba...</div>;
    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className='row'>
                <div className="col-lg-9">
                                <div>
                                    <div className="nadpis_padding">
                                        <h3>{info.attributes.name}</h3>
                                    </div>
                                    <hr className="green-line"/>
                                    <div>
                                        {/* <ReactMarkdown key={inf.id}>
                                            {inf.attributes.description}
                                        </ReactMarkdown> */}
                                        <BlocksRenderer
                                            content={
                                            Array.isArray(info?.attributes?.description) ? info.attributes.description : []
                                            }
                                        ></BlocksRenderer>
                                    </div>
                                </div>
                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                        {t("zpet")}
                    </button>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );


}

export default Info;