import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
// import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import CarouselWithMap from "./Components_2/CarouselWithMap";
import SidebarMenu from "./Components_2/sideBarMenu";
import moment from 'moment';

function AktualneDetail() {
    const [t, i18n] = useTranslation("global");
    let { id } = useParams();

    const [aktualniDetail, setAktualniDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + `/api/kpbi-aktualities/${id}?locale=${i18n.language}&populate=*`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAktualniDetail(data.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [id, i18n.language]);

    const findLocalization = (data, language) => {
        let localization = {};

        if (data.attributes.locale !== language) {
            const foundLocalization = data.attributes.localizations?.data.find(loc => loc.attributes.locale === language);

            if (foundLocalization) {
                localization.name = foundLocalization.attributes.name;
                localization.description = foundLocalization.attributes.description;
            } else {
                localization.name = data.attributes.name;
                localization.description = data.attributes.description;
            }
        } else {
            localization.name = data.attributes.name;
            localization.description = data.attributes.description;
        }

        return localization;
    };

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba...</div>;

    const content = findLocalization(aktualniDetail, i18n.language);
// console.log(aktualniDetail); //
    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap />
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className='details-container'>
                        <h1 className="details-title">{content.name}</h1>
                        <div className='details-date'>{moment(aktualniDetail.attributes.publishedAt).format('DD. MM. YYYY')}</div>
                        <div className="details-description">
                            {/* <ReactMarkdown key={aktualniDetail.id}>
                                {content.description}
                            </ReactMarkdown> */}
                            <BlocksRenderer
                                content={
                                Array.isArray(content?.description) ? content.description : []
                                }
                            ></BlocksRenderer>
                        </div>

                        {aktualniDetail.attributes?.files?.data?.length > 0 && (
                            <div className="details-files">
                                <h2>{t("soubory")}</h2>
                                {aktualniDetail.attributes.files.data.map((file) => (
                                    <div key={file.id} className='file-link'>
                                        <a href={`${process.env.REACT_APP_API_URL}${file.attributes.url}`}>
                                            {file.attributes.name}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}

                        {aktualniDetail.attributes?.coverImage?.data && aktualniDetail.attributes.coverImage.data.length > 0 && (
                            <div className="details-images">
                                <div className="">
                                    {aktualniDetail.attributes.coverImage.data.map((image, index) => (
                                        image.attributes && (
                                            <img
                                                key={index}
                                                src={`${process.env.REACT_APP_API_URL}${image.attributes.url}`}
                                                alt={`Cover ${index}`}
                                                className='img-fluid center-image'
                                            />
                                        )
                                    ))}
                                </div>
                            </div>
                        )}

                        {aktualniDetail.attributes?.videa?.data && aktualniDetail.attributes.videa.data.length > 0 && (
                            <div className="details-videos">
                                <h2>{t("videa")}</h2>
                                <div className="video-container">
                                    {aktualniDetail.attributes.videa.data.map((video, index) => (
                                        video.attributes && (
                                            <video width="750" height="500" key={index} controls>
                                                <source src={`${process.env.REACT_APP_API_URL}${video.attributes.url}`} />
                                            </video>
                                        )
                                    ))}
                                </div>
                            </div>
                        )}

                        <br />
                        <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                            {t("zpet")}
                        </button>
                    </div>
                </div>
                <SidebarMenu />
            </div>
        </div>
    );
}

export default AktualneDetail;
