import React, { useState, useEffect, useCallback } from 'react';
import './Vim_kam_klikam.css'
import CarouselWithMap from "./Components_2/CarouselWithMap";
import SidebarMenu from "./Components_2/sideBarMenu";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";



function VimKamKlikam() {
    const [t] = useTranslation("global");
    const [clanky, setClanky] = useState([]);
    const [kategorie, setKategorie] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchClankyKategorie = useCallback(async (kategorieId) => {
        try 
        {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/kpbi-vim-kam-klikam-clankies?populate=*&sort[0]=id:desc&filters[kategorie][id][$eq]=${kategorieId}&pagination[pageSize]=1`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // console.log(data.data);
            return data.data; 
        } catch (error) 
        {
            console.error("Nenašlo data", kategorieId, error);
        }
    }, []);

    useEffect(() => {
        if (kategorie.length > 0) {
            setLoading(true);
            const fetchPromises = kategorie.map(kat => fetchClankyKategorie(kat.id));
    
            Promise.all(fetchPromises).then(data => {
                const fetchedArticles = data.filter(Boolean).flat();
                setClanky(fetchedArticles);
                setLoading(false);
            }).catch(error => {
                setError(error);
                setLoading(false);
            });
        }
    }, [kategorie, fetchClankyKategorie]);
    
    

    useEffect(() => {
        setLoading(true);
        console.log(process.env.REACT_APP_API_URL +`/api/kpbi-vim-kam-klikam-kategories?populate=*`);
        fetch(process.env.REACT_APP_API_URL +`/api/kpbi-vim-kam-klikam-kategories?populate=*`) //
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setKategorie(data.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);
    
    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;

    // console.log(kategorie);
    console.log(clanky);
    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("vim_kam_klikam")}</h3>
                            <hr className="green-line"/>
                        </div>
                        {
                            kategorie.map(kat => (
                                <div className="col-md-6 mb-4">
                                    <Link to={`/vim-kam-klikam-clanky/${kat.id}`} className="odkaz_projekt">
                                        <div key={kat.id} className="image-container">
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}${kat.attributes.image.data.attributes.url}`}
                                                alt="dite" className="picture_vim_kam_klikam"/>
                                            <div className="menu_klikam">
                                                <p className='card-title'>{kat.attributes.nazev}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                        <div className="col-lg-12">
                            <h3>NEMĚLO BY VÁM UNIKNOUT</h3>
                            <hr className="green-line"/>
                        </div>
                        {
                            clanky.map(clanek => (
                                <div className="col-md-6 mb-4">
                                    <div key={clanek.id} className="nesmi_uniknout card card-nesmi-uniknout">
                                        <Link
                                            to={`/vim-kam-klikam-clanky-detail/${clanek.id}/${clanek.attributes.kategorie.data.id}`}
                                            className="odkaz_projekt">
                                            <div className="card-body">
                                                <h5 className="card-title" style={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 3
                                                }}>{clanek.attributes.perex}</h5>
                                                <div className="vice-link-container">
                                                    {t("vice")}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default VimKamKlikam;