import React from 'react';
import './Footer.css';
import {useTranslation} from "react-i18next";

function Footer() {
  const [t] = useTranslation("global");
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-logo">
          <img className="img-fluid" src={`${process.env.REACT_APP_API_URL}/uploads/logo_bottom_8211ec2fbe.png`} alt="Logo" />
        </div>
        <div className="footer-text">
          <p>© 2024 Kraje pro bezpečný internet</p>
          <p>{t("footer_text")}</p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
