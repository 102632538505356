import React, { useState, useEffect, useCallback } from 'react';
import SidebarMenu from "./Components_2/sideBarMenu";
import { useNavigate , Link , useParams} from 'react-router-dom';
import CarouselWithMap from './Components_2/CarouselWithMap';
import {useTranslation} from "react-i18next";
import SearchBar from './Components_2/Search_bar';
import common from "./pics/logo_top.png";

function VimKamKlikamClanky() {
    const [t] = useTranslation("global");
    const [pagination, setPagination] = useState({ page: 1, pageCount: 0, pageSize: 20, total: 0 });
    let { id } = useParams();
    const [clanky, setClanky] = useState([]);
    const [kategorie, setKategorie] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('');
    const [currentFilter, setCurrentFilter] = useState('');
    const navigate = useNavigate();
    const [isCoverDivVisible, setIsCoverDivVisible] = useState(false);

    const fetchData = useCallback((pageNumber, pageSize, filter) => {
        setLoading(true);
        const filterQuery = filter ? `&filters[titulek][$containsi]=${filter}` : '';
        const url = `${process.env.REACT_APP_API_URL}/api/kpbi-vim-kam-klikam-clankies?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&populate=*&sort[0]=id:desc&filters[kategorie][id][$eq]=${id}${filterQuery}`;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(cbk => {
                // console.log(cbk);
                setPagination(prevState => ({
                    ...prevState,
                    pageCount: cbk.meta.pagination.pageCount,
                    total: cbk.meta.pagination.total,
                }));
                
                const fetchKategoriePromises = cbk.data.map(clanek => {
                    return fetch(process.env.REACT_APP_API_URL +`/api/kpbi-vim-kam-klikam-kategories/${clanek.attributes.kategorie.data.id}`)
                    .then(response => response.json())
                    .then(kategorieData => {
                        return { ...clanek, kategorie: kategorieData.data };
                    });
                });
                
                return Promise.all(fetchKategoriePromises);
            })
            .then(clankyWithKategorie => {
                // console.log(clankyWithKategorie);
                setClanky(clankyWithKategorie);
                setKategorie(clankyWithKategorie[0].kategorie.attributes.nazev);
                setLoading(false);
            })
            .catch(error => {
                setKategorie("Články nenalezeny");
                setError(error);
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        fetchData(pagination.page, pagination.pageSize, filter);
        setCurrentFilter(filter);
    }, [pagination.page, pagination.pageSize, filter, fetchData]);


    const handlePageChange = (newPage) => {
        setPagination(prevState => ({ ...prevState, page: newPage }));
    };

const handleFilter = (value) =>
{
    setFilter(value);
    setPagination(prevState => ({ ...prevState, page: 1 }));
    fetchData(1, pagination.pageSize, value);
    setCurrentFilter(value);
};

    // console.log(clanky);
    if (loading) return <div>Načítání...</div>;
    if (error) return  <div className="nadpis_padding d-flex justify-content-between">
                            <div>
                                <h3>{kategorie}</h3>
                            </div>
                            <div style={{marginRight: '0px'}}>
                                <button type='submit' className='btn btn-primary btn-back btn_vice' onClick={()=>window.location.reload()}>Reset</button>
                            </div>
                        </div>;

    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className='row d-flex'>
                <div className='col-lg-9'>
                    <div className="col-lg-12 card-container_">
                        <div className="nadpis_padding mx-auto pb-3">
                            <div>
                                <h3 className='text-center'>{kategorie}</h3>
                            </div>
                            <div style={{marginRight: '0px'}}>
                                <SearchBar onSubmit={handleFilter}/>
                            </div>
                        </div>
                        {currentFilter && (
                            <>
                                <br/>
                                <div className="filtr_div">Byl použit filtr: {currentFilter}</div>
                            </>
                        )}
                        <hr className="green-line"/>
                        <div className='row' style={{display: 'flex'}}>
                            {clanky.map(clanek => (
                                <div key={clanek.id} className='col-md-6 mb-4 flex-column'
                                     id={`card-${clanek.id}`}>
                                    <Link to={`/vim-kam-klikam-clanky-detail/${clanek.id}/${clanek.kategorie.id}`}
                                          style={{textDecoration: 'none', color: 'black'}}>
                                        <div className='card h-100'>
                                            <div className="box_shadow">
                                                {isCoverDivVisible === clanek.id ? (
                                                    <div className='cover-div'
                                                         onMouseEnter={() => setIsCoverDivVisible(clanek.id)}
                                                         onMouseLeave={() => setIsCoverDivVisible(null)}>
                                                        <div className='cover-text'> Máme radost, článek se vám líbí. Už
                                                            si ho
                                                            otevřelo {clanek.attributes.pocet_zobrazeni} lidí, <Link
                                                                to={`/vim-kam-klikam-clanky-detail/${clanek.id}/${clanek.kategorie.id}`}
                                                                style={{color: 'black'}}>buďte další</Link>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <img className="card-img-top"
                                                     src={clanek.attributes.coverImage.data ? process.env.REACT_APP_API_URL + clanek.attributes.coverImage.data.attributes.url : common}
                                                     alt={clanek.attributes.name}/>
                                                <hr className="card_hr"/>
                                                <div className="overlay-vimkamklikam">
                                                    <div className="circle-number-vimkamklikam"
                                                         onMouseEnter={() => setIsCoverDivVisible(clanek.id)}
                                                         onMouseLeave={() => setIsCoverDivVisible(null)}>
                                                        {clanek.attributes.pocet_zobrazeni + 'x'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body'>
                                                <h4 className='card-title'>{clanek.attributes.titulek}</h4>
                                                <h6 className='pb-3'>{clanek.kategorie ? clanek.kategorie.attributes.nazev : 'Kategorie nenalezena'}</h6>
                                                <p className='card-text'>{clanek.attributes.perex}</p>
                                                <p className="vice"><u>{t("vice")}</u></p>
                                            </div>
                                        </div>
                                    </Link>
                                    <br/>
                                </div>
                            ))}
                        </div>
                    </div>
                        {pagination.pageCount > 1 && (
                            <div className='d-flex justify-content-center'>
                                <ul className='pagination'>
                                    {[...Array(pagination.pageCount)].map((_, index) => (
                                        <li
                                            key={index}
                                            className={`page-item  ${pagination.page === index + 1 ? 'active' : ''}`}
                                        >
                                            <button
                                                className='page-link'
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                        {t("zpet")}
                    </button>
                </div>
                <SidebarMenu/>
            </div>
        </div>
    );
};

export default VimKamKlikamClanky;